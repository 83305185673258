import type { ComponentProps, ReactNode } from 'react'

import type { AsyncButton } from '@ocho/aurora'
import { create } from 'zustand'
import { combine } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'

export type AlertAction = {
  label: string
  onClick?: (noteRef: any) => Promise<unknown>
} & Omit<ComponentProps<typeof AsyncButton>, 'onClick'>

export type AlertOptions = {
  actions?: AlertAction[]
  children?: ReactNode
  /**
   * @deprecated use 'message' instead
   */
  description?: ReactNode
  isCancelable?: boolean
  message?: ReactNode
  onClose?: () => void
  // FIXME: this props can be changed for a type
  showComment?: boolean
  title?: string
  titleHelper?: string
}

/**
 * Store
 */

export const useAlertStore = create(
  combine({ current: null as AlertOptions | null }, (set) => ({
    dismiss() {
      set({ current: null })
    },
    show(alertOptions: AlertOptions) {
      set({ current: alertOptions })
    },
  })),
)

/**
 * Helpers
 */

export function useShowAlert() {
  return useAlertStore(useShallow((state) => state.show))
}

export function useDismissAlert() {
  return useAlertStore(useShallow((state) => state.dismiss))
}

export function useAlert() {
  return useAlertStore(
    useShallow(({ current, dismiss }) => [current, dismiss] as const),
  )
}
